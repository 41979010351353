:root {
  --main-bg-color: #ffffff;
  --main-text-color: #000000;
  --input-bg: #ffffff;
  --input-border: #ccccff;
  --input-text: #000000;
}

body.dark-mode {
  --main-bg-color: #121212;
  --main-text-color: #ffffff;
  --input-bg: #1f1f1f;
  --input-border: #8888ff;
  --input-text: #ffffff;
}

.theme-excited {
  --main-bg-color: #fff8dc;
}

.theme-calm {
  --main-bg-color: #e0f7fa;
}

.theme-neutral {
  --main-bg-color: #f0f0f0;
}

.theme-sad {
  --main-bg-color: #e3f2fd;
}

.theme-angry {
  --main-bg-color: #ffebee;
}

body,
.chat-container {
  background-color: var(--main-bg-color);
  color: var(--main-text-color);
}

input[type="text"] {
  background-color: var(--input-bg);
  color: var(--input-text);
  border: 1px solid var(--input-border);
  padding: 10px;
  width: 100%;
  border-radius: 8px;
}

.chat-footer button {
  margin-left: 5px;
  border: none;
  background: transparent;
  color: var(--main-text-color);
  cursor: pointer;
  font-size: 18px;
}

.mood-option {
  cursor: pointer;
  font-size: 28px;
  margin: 0 8px;
}

.mood-option:hover::after {
  content: attr(title);
  display: block;
  position: absolute;
  font-size: 12px;
  color: #444;
  background: #fff;
  padding: 2px 6px;
  border-radius: 4px;
  transform: translateY(20px);
  white-space: nowrap;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.chat-app {
  display: flex;
  height: 100vh;
  overflow: hidden;
}

.chat-container {
  flex: 1;
  padding: 20px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.mood-selector-centered {
  text-align: center;
  margin-top: -80px;
}

.emoji-row {
  font-size: 32px;
  margin-top: 20px;
}

.mood-option {
  cursor: pointer;
  margin: 0 8px;
  position: relative;
}

.mood-option:hover::after {
  content: attr(data-tooltip);
  position: absolute;
  bottom: -30px;
  left: 50%;
  transform: translateX(-50%);
  background: #fff;
  padding: 3px 8px;
  font-size: 12px;
  color: #333;
  border-radius: 4px;
  white-space: nowrap;
  box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.2);
}

@keyframes softGlow {
  0% {
    box-shadow: 0px 0px 15px rgba(100, 100, 100, 0.1);
  }
  50% {
    box-shadow: 0px 0px 30px rgba(100, 100, 100, 0.3);
  }
  100% {
    box-shadow: 0px 0px 15px rgba(100, 100, 100, 0.1);
  }
}

.glow-effect {
  animation: softGlow 5s ease-in-out infinite alternate;
}

@tailwind base;
@tailwind components;
@tailwind utilities;
